import React from "react";
import { Document, Page } from "react-pdf";

export default function SinglePage(props) {
  return (
    <>
      <Document file={props.pdf}>
        <Page pageNumber={1} />
      </Document>
    </>
  );
}
